<template>
  <svg
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.36872 6.38128C9.71043 6.72299 9.71043 7.27701 9.36872 7.61872L5.86872 11.1187C5.52701 11.4604 4.97299 11.4604 4.63128 11.1187C4.28957 10.777 4.28957 10.223 4.63128 9.88128L7.51256 7L4.63128 4.11872C4.28957 3.77701 4.28957 3.22299 4.63128 2.88128C4.97299 2.53957 5.52701 2.53957 5.86872 2.88128L9.36872 6.38128Z"
    />
  </svg>
</template>
